<template>
  <div>
    <Head>
      <title>O nama</title>
      <meta name="description" content="Ezra je osiguravajuća kuća koja se usredotočuje na pružanje najboljih usluga svojim klijentima" />
    </Head>    <!-- Start Hero -->
    <section class="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
      <div class="container">
        <div class="grid grid-cols-1 text-center mt-10">
          <h1 class="text-3xl leading-normal font-semibold">O nama</h1>
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <div class="relative">
      <div class="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
        <svg class="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
        </svg>
      </div>
    </div>
    <!-- End Hero -->

    <!-- Start Section-->
    <section class="relative md:py-24 md:pb-20 py-16">
      <div class="container">
        <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
          <div class="md:col-span-12">
            <div class="">
              <div class="flex flex-col mb-4 justify-center items-center">
                <div class="flex">
                  <span class="text-red-600 text-2xl font-bold mb-0"><span class="counter-value text-6xl font-bold" data-target="25">25</span>+</span>
                  <span class="self-end font-medium ml-2">Godina <br /> Iskustva</span>
                </div>
                <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Ko je EZRA?</h3>
              </div>
              <div class="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
                <div class="lg:col-span-6 md:col-span-6 col-span-12">
                  <div class="team p-6 rounded-md border border-gray-100 dark:border-gray-700 group bg-white dark:bg-slate-900 text-center">
                    <div class="flex justify-center items-center">
                      <img src="/landing/assets/images/client/emir_dzanovic.jpg" class="h-24 w-24 rounded-full shadow-md dark:shadow-gray-800" alt="Emir Džanović" />
                    </div>
                    <div class="content mt-4 text-center">
                      <a href="https://www.linkedin.com/in/emir-d-642a8380/" target="_blank" class="text-lg font-medium hover:text-red-600 block mt-2">Emir Džanović</a>
                      <span class="text-slate-400 block">Osnivač <icon class="h-4 w-4 inline-block fill-black" name="sigma" /> @EZRA</span>
                      <p class="text-slate-400 mt-4">8+ godina “vojnog roka” u osiguranju, Uniqa, Wiener i Central. Još koja godina spartanskog učenja u špediciji, telekomunikacijama i e-commerce. Mnoge bitke, mnoge pobjede, poneko povlačenje. Sve prošao, uvijek učio, mislio na klijenta i obraz čuvao. Prije toga diplomirani pravnik, uskoro gotov MA. Priboj rodio – Sarajevo odgojilo, Konjic mi je drag. Hip-hop, outdoor, kuhanje, mačke(I cuke su OK)… Atletski šampion BiH u 2 discipline. Sad idemo da osvojimo tržište osiguranja u BiH. </p>
                      <ul class="list-none mt-4">
                        <li class="inline"><a href="https://www.linkedin.com/in/emir-d-642a8380/" target="_blank" class="btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md hover:border-red-600 dark:hover:border-red-600 hover:bg-gray-100 hover:text-white"><icon class="h-5 w-5 inline-block fill-gray-100" name="linkedin" /></a></li>
                      </ul><!--end icon-->
                    </div>
                  </div>
                </div>
                <div class="lg:col-span-6 md:col-span-6 col-span-12">
                  <div class="team p-6 rounded-md border border-gray-100 dark:border-gray-700 group bg-white dark:bg-slate-900 text-center">
                    <div class="flex justify-center items-center">
                      <img src="/landing/assets/images/client/amir_karahasanovic.jpg" class="h-24 w-24 rounded-full shadow-md dark:shadow-gray-800" alt="Amir Karahasanović" />
                    </div>
                    <div class="content mt-4 text-center">
                      <a href="https://www.linkedin.com/in/amir-karahasanovic/" target="_blank" class="text-lg font-medium hover:text-red-600 block mt-2">Amir Karahasanović</a>

                      <span class="text-slate-400 block">Osnivač <icon class="h-4 w-4 inline-block fill-black" name="sigma" /> @EZRA</span>
                      <p class="text-slate-400 mt-4">3+ godina Uniqa škole u osiguranju. Slijedi 9 godina prodaje i marketinga u 7 industrija. Slobodan duh, nisam se skrasio na mjestu, ali sam se ukrasio iskustvom i razumijevanjem. Usponi i par padova, ali uvijek samo naprijed i još bolje. Sa klijentom uvijek iskreno. Obrazovanje – filozof i sociolog u pripremi, ali to je ipak više za dušu. Idealna kombinacija haustorčeta i mahalca, od Dobrinje do Mejtaša. Košarka i MMA fan, malo geek i nerd, cuke(i mace su OK)… Prvi mentor u osiguranju atletskom šampionu BiH u 2 discipline. Sad idemo da osvojimo tržište osiguranja u BiH. </p>
                      <ul class="list-none mt-4">
                        <li class="inline"><a href="https://www.linkedin.com/in/amir-karahasanovic/" target="_blank" class="btn btn-icon btn-sm border border-gray-100 dark:border-gray-800 rounded-md hover:border-red-600 dark:hover:border-red-600 hover:bg-gray-100 hover:text-white"><icon class="h-5 w-5 inline-block fill-gray-100" name="linkedin" /></a></li>
                      </ul><!--end icon-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <!-- End Section-->
    <!-- Start -->
    <section class="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
      <div class="container">
        <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
          <div class="lg:col-span-5 md:col-span-6">
            <div class="grid grid-cols-12 gap-6 items-center">
              <div class="col-span-6">
                <div class="grid grid-cols-1 gap-6">
                  <img src="/landing/assets/images/insurance/ab01.jpg" class="shadow rounded-md" alt="" />
                  <img src="/landing/assets/images/insurance/ab02.jpg" class="shadow rounded-md" alt="" />
                </div>
              </div>
              <div class="col-span-6">
                <div class="grid grid-cols-1 gap-6">
                  <img src="/landing/assets/images/insurance/ab03.jpg" class="shadow rounded-md" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="lg:col-span-7 md:col-span-6">
            <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
              <div class="col-span-12">
                <div class="sticky top-20">
                  <ul id="myTab" class="flex-column text-center p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md" data-tabs-toggle="#myTabContent" role="tablist">
                    <li role="presentation">
                      <button id="profile-tab" class="px-4 py-2 text-base font-semibold rounded-md w-full hover:text-red-600 transition-all duration-500 ease-in-out" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true">The Story</button>
                    </li>
                    <li role="presentation">
                      <button id="dashboard-tab" class="px-4 py-2 text-base font-semibold rounded-md w-full mt-3 hover:text-red-600 transition-all duration-500 ease-in-out" data-tabs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="false">The Business</button>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-span-12">
                <div id="myTabContent" class="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                  <div id="profile" class="" role="tabpanel" aria-labelledby="profile-tab">
                    <div class="mt-6">
                      <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">The Story</h3>
                      <div class="text-slate-400 max-w-xl">
                        <p>Ezra, izvorno staro-hebrejski, znači pomagač, pomoć, snaga, prijatelj. Dugo smo tražili ime, i nije slučajno da smo odabrali ovakvog značenja. Neka toliko bude rečeno o samom imenu.</p>
                        <p>Prije profesionalnog predstavljanja, koje je neizbježno zbog standarda struke, želimo ti reći ne&scaron;to su&scaron;tinsko o nama, kako bi se upoznali. Nećemo dugo, ali ako te ne zanima samo brzo skrolaj dalje do &ldquo;biznis&rdquo; dijela.</p>
                        <p>Projekat EZRA, kao i drugi samostalni poslovni pothvati, je sebičan poku&scaron;aj da se stekne sloboda i mir. Drugi, čast izuzecima, predstavljaju da rade samo za tvoj interes ili neki vi&scaron;i cilj. Mi obećavamo da je upravo na&scaron; interes ujedno i veliko dobro za tebe. Kako&hellip;nije te&scaron;ko.</p>
                        <p>Poslovni odnos vi&scaron;e neće biti samo isključivo numerisan i kvantificiran. Prodaja, kupovina, trgovina, ponuda, potražnja&hellip;plus, minus. Dalje nastavljamo samo prema pozitivnom feedback-u na&scaron;ih klijentata iz proteklih zajedničkih 25 godina iskustva. Samo prijateljski i sa mnogo du&scaron;e. Gradimo odnos i povjerenje, pružajući fantastičnu uslugu usmjerenu samo na tvoje zadovoljstvo. Brinemo za i o tebi kao najbližem prijatelju ili rodbini. Apsolutno nužan prirodan ishod takvog pristupa je na&scaron; uspjeh kao &ldquo;sebični interes&rdquo;. Nikakav cilj, rezultat, ili neka druga prodajna metrika neće biti na&scaron; interes. ROI na uloženo &ldquo;dobro&rdquo; je uvijek opet samo &ldquo;dobro&rdquo;.</p>
                        <p>Nakon skoro 25 godina zajedničkog profesionalnog iskustva, 10 industrija i 10 kompanija, svjetski brendovi i korporacije i lokalni SME-ovi, mnogo učenja, rada i prevladanih izazova, do&scaron;lo je vrijeme za nove stvari. Svaka čast, hvala i sretno dalje svima koji su bili dio na&scaron;eg putovanja. Dalje nastavljamo samo mi i na&scaron;i klijenti.</p>
                        <p>Sretnim spletom okolnosti, uz lične uslove i djelatnost je takva da omogućava ovakav novi pristup, a trži&scaron;te ga zahtijeva. &Scaron;to možda za neke druge, na žalost, nije moguće. U osiguranju ćemo da pokažemo da može mnogo bolje i ljep&scaron;e.</p>
                        <p>Hvala i dobro do&scaron;li! :D</p>
                      </div>
                    </div>
                  </div>
                  <div id="dashboard" class="hidden " role="tabpanel" aria-labelledby="dashboard-tab">
                    <div class="mt-6">
                      <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">The Business</h3>
                      <div class="text-slate-400 max-w-4xl mx-auto">
                        <p>EZRA je konsultantska agencija za zastupanje i posredovanje u poslovima osiguranja, za fizička i pravna lica. Želimo biti prvi odabir na&scaron;ih klijenata i partner od povjerenja, te ponuditi bolji način poslovanja na trži&scaron;ta osiguranja u BiH.</p>
                        <p>Cilj nam je klijentima pružiti najbolje opcije i ponude osiguravajućih pokrića, te podr&scaron;ke i asistencije, kako bi se osigurala njihova finansijska sigurnost i za&scaron;tita imovine. Kao nezavisni zastupnici sa &nbsp;preko 10 godina iskustva u osiguranju, na&scaron;a agencija sarađuje sa svim značajnim osiguravajućim kompanija u BiH, te za Vas pronalazimo rje&scaron;enja za sve vrste osiguranja, &scaron;to nam omogućava da pružimo najbolji izbor za na&scaron;e klijente.</p>
                        <p>Jasno nam je da snalaženje u složenom svijetu osiguranja može biti zamorno i dosadno. Zato nudimo asistenciju i savjetovanje prilikom prijave i naplate &scaron;tete. Na&scaron; tim voditi će vas kroz cijeli proces, brinući se da va&scaron;a prava budu za&scaron;tićena i isplata &scaron;tete zadovoljavajuća.</p>
                        <p>Posvećeni smo pružanju najboljeg korisničkog iskustva. Fokus je manje stresa za klijenta, potrebe klijenta, te personalizirana rje&scaron;enja prilagođena va&scaron;im specifičnim potrebama.</p>
                        <p>Vrijeme je novac. Zato nudimo sveobuhvatna outsourcing i konzultantska rje&scaron;enja, sve na jednom mjestu za Va&scaron;e potrebe u poslovima osiguranja. Želimo biti Va&scaron; partner i prijatelj od povjerenja, zastupajući Va&scaron;e interese u svakom trenutku.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div><!--end grid-->
          </div>
        </div>
      </div><!--end container-->
    </section>

    <section class="relative md:py-24 py-16">
      <div class="container md:mt-24">
        <div class="grid grid-cols-1 text-center">
          <h6 class="text-red-600 text-sm font-bold mb-2">Misliš o osiguranju za sebe, porodicu ili posao.</h6>
          <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            Nisi siguran/na? Mi ćemo pomoći da nađeš rješenje.
          </h3>
          <p class="text-slate-400 max-w-xl mx-auto">Kontaktiraj nas i saznaj kako možemo pomoći, prilagođeno tvojim potrebama i budžetu.</p>
          <div class="mt-6">
            <Link :href="route('contact')" class="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md mr-2 mt-2 cursor-pointer"><icon class="w-4 w-4 inline-block fill-gray-100" name="send_plan" /> Zovi na kafu</Link>
          </div>
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <!-- End -->
  </div>
</template>
<script>
import Layout from '@/Shared/Landing/Layout'
import Icon from '@/Shared/Icon'
import {Head, Link} from '@inertiajs/inertia-vue3'
import {tns} from 'tiny-slider'
export default {
    components: {
        Icon,
        Head,
        Link,
    },
    layout: Layout,
    created() {
        this.initTabs()
    },
    methods: {
        initTabs: function() {
            try {
                const Default = {
                    defaultTabId: null,
                    activeClasses: 'text-white bg-red-600',
                    inactiveClasses: 'hover:text-red-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800',
                    onShow: () => {
                    },
                }

                class Tabs {
                    constructor(items = [], options = {}) {
                        this._items = items
                        this._activeTab = options ? this.getTab(options.defaultTabId) : null
                        this._options = {...Default, ...options}
                        this._init()
                    }

                    _init() {
                        if (this._items.length) {
                            // set the first tab as active if not set by explicitly
                            if (!this._activeTab) {
                                this._setActiveTab(this._items[0])
                            }

                            // force show the first default tab
                            this.show(this._activeTab.id, true)

                            // show tab content based on click
                            this._items.map(tab => {
                                tab.triggerEl.addEventListener('click', () => {
                                    this.show(tab.id)
                                })
                            })
                        }
                    }

                    getActiveTab() {
                        return this._activeTab
                    }

                    _setActiveTab(tab) {
                        this._activeTab = tab
                    }

                    getTab(id) {
                        return this._items.filter(t => t.id === id)[0]
                    }

                    show(id, forceShow = false) {
                        const tab = this.getTab(id)

                        // don't do anything if already active
                        if (tab === this._activeTab && !forceShow) {
                            return
                        }

                        // hide other tabs
                        this._items.map(t => {
                            if (t !== tab) {
                                t.triggerEl.classList.remove(...this._options.activeClasses.split(' '))
                                t.triggerEl.classList.add(...this._options.inactiveClasses.split(' '))
                                t.targetEl.classList.add('hidden')
                                t.triggerEl.setAttribute('aria-selected', false)
                            }
                        })

                        // show active tab
                        tab.triggerEl.classList.add(...this._options.activeClasses.split(' '))
                        tab.triggerEl.classList.remove(...this._options.inactiveClasses.split(' '))
                        tab.triggerEl.setAttribute('aria-selected', true)
                        tab.targetEl.classList.remove('hidden')

                        this._setActiveTab(tab)

                        // callback function
                        this._options.onShow(this, tab)
                    }

                }

                window.Tabs = Tabs

                document.addEventListener('DOMContentLoaded', () => {
                    document.querySelectorAll('[data-tabs-toggle]').forEach(triggerEl => {

                        const tabElements = []
                        let defaultTabId = null
                        triggerEl.querySelectorAll('[role="tab"]').forEach(el => {
                            const isActive = el.getAttribute('aria-selected') === 'true'
                            const tab = {
                                id: el.getAttribute('data-tabs-target'),
                                triggerEl: el,
                                targetEl: document.querySelector(el.getAttribute('data-tabs-target')),
                            }
                            tabElements.push(tab)

                            if (isActive) {
                                defaultTabId = tab.id
                            }
                        })
                        new Tabs(tabElements, {
                            defaultTabId: defaultTabId,
                        })
                    })
                })
            } catch (error) {
                console.log(error)
            }
        }

    },

}
</script>
