<template>
  <div>
      <Head>
          <title>Česta pitanja</title>
          <meta name="description" content="Imate pitanja vezana za osiguranja pogledajte našu bazu pitanja i odgovara." />
      </Head>
    <!-- Start Hero -->
    <section class="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
      <div class="container">
        <div class="grid grid-cols-1 text-center mt-10">
          <h1 class="text-3xl leading-normal font-semibold">Česta pitanja</h1>
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <div class="relative">
      <div class="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
        <svg class="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
        </svg>
      </div>
    </div>
    <!-- End Hero -->

    <!-- Start Section-->
    <section class="relative py-16">
      <div class="container">
        <div class="flex items-center justify-center mb-8"><search-input v-model="form.search" class="w-full max-w-md mr-4" @reset="reset" /></div>
        <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
          <div class="lg:col-span-12 md:col-span-12">
            <div id="tech">
              <div id="accordion-collapseone" data-accordion="collapse" class="mt-6">
                <div v-for="(faq, fi) in faqs.data" :key="faq.id" class="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                  <h2 :id="'accordion-collapse-heading-'+fi" class="text-base font-semibold">
                    <button
                      type="button" class="flex justify-between items-center p-5 w-full font-medium text-left"
                      :class="{'bg-gray-50 dark:bg-slate-800 text-red-600':faq.active}"
                      :data-accordion-target="'#accordion-collapse-body-'+fi"
                      aria-expanded="true" :aria-controls="'accordion-collapse-body-'+fi" @click="toggleActive(fi)"
                    >
                      <span>{{ faq.name }}</span>
                      <svg data-accordion-icon class="w-4 h-4 shrink-0" :class="{'rotate-180' : faq.active}" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>
                    </button>
                  </h2>
                  <div :id="'accordion-collapse-body-'+fi" :class="{'hidden':!faq.active}" :aria-labelledby="'accordion-collapse-heading-'+fi">
                    <div class="p-5">
                      <p class="text-slate-400 dark:text-gray-400" v-html="sanitizeHtml(faq.details)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><!--end grid-->
      </div><!--end container-->

      <div class="container md:mt-24 mt-16">
        <div class="grid grid-cols-1 text-center">
            <h6 class="text-red-600 text-sm font-bold mb-2">Misliš o osiguranju za sebe, porodicu ili posao.</h6>
            <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                Nisi siguran/na? Mi ćemo pomoći da nađeš rješenje.
            </h3>
            <p class="text-slate-400 max-w-xl mx-auto">Kontaktiraj nas i saznaj kako možemo pomoći, prilagođeno tvojim potrebama i budžetu.</p>
          <div class="mt-6">
              <Link :href="route('contact')" class="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md mr-2 mt-2 cursor-pointer"><icon class="w-4 w-4 inline-block fill-gray-100" name="send_plan" /> Zovi na kafu</Link>
          </div>
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <!-- End Section-->
  </div>
</template>
<script>
import Layout from '@/Shared/Landing/Layout'
import {Head, Link} from '@inertiajs/inertia-vue3'
import sanitizeHtml from 'sanitize-html'
import SearchInput from '@/Shared/SearchInput'
import throttle from 'lodash/throttle'
import pickBy from 'lodash/pickBy'
import mapValues from 'lodash/mapValues'
import Icon from "@/Shared/Icon.vue";
export default {
    components: {
        Head,
        Link,
        SearchInput,
        Icon,
    },
    layout: Layout,
    props: {
        faqs: Object,
        title: String,
        filters: Object,
    },
    data() {
        return {
            form: {
                search: this.filters.search,
            },
        }
    },
    watch: {
        form: {
            deep: true,
            handler: throttle(function() {
                this.$inertia.get(this.route('faq'), pickBy(this.form), { preserveState: true })
            }, 150),
        },
    },

    created() {
        if(this.faqs.data.length){
            this.faqs.data[0].active = true
        }
    },
    methods:{
        toggleActive(index){
            this.faqs.data[index].active = !this.faqs.data[index].active
        },
        sanitizeHtml : sanitizeHtml,
        reset() {
            this.form = mapValues(this.form, () => null)
        },
    },
}
</script>
