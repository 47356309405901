<template>
  <div>
    <Head :title="__(title)" />
    <!-- Start Hero -->
    <!-- Start Hero -->
    <section class="relative table w-full py-28 lg:py-32 bg-gray-50 bg-rainbow dark:bg-slate-800">
      <div class="container">
        <div class="grid grid-cols-1 text-center mt-10">
          <h1 class="text-3xl leading-normal font-semibold">{{ post.title }}</h1>
          <ul class="list-none mt-6">
            <li v-if="author_name" class="inline-block font-semibold mx-5"> <span class=" block">Autor :</span> <span class="block">{{ author_name }}</span></li>
            <li class="inline-block font-semibold mx-5"> <span class=" block">Datum :</span> <span class="block">{{ post.created_at }}</span></li>
            <li class="inline-block font-semibold mx-5"> <span class=" block">Vrijeme :</span> <span class="block">{{ getReadingTime(post.details) }}</span></li>
          </ul>
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <div class="relative">
      <div class="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
        <svg class="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
        </svg>
      </div>
    </div>
    <!-- End Hero -->

    <!-- Start Section-->
    <section class="relative md:py-24 py-16">
      <div class="container">
        <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
          <div class="lg:col-span-8 md:col-span-6">
            <div class="p-6 rounded-md">
              <img v-if="post.image" :src="post.image" class="rounded-md" :alt="post.title" />

              <div class="post-details html" v-html="sanitizeHtml(post.details)" />
            </div>
          </div>

          <div class="lg:col-span-4 md:col-span-6">
            <div class="sticky top-20">
              <h5 class="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center">Autor</h5>
              <div class="text-center mt-8">
                <img v-if="post.author.photo_path" :src="post.author.photo_path" class="h-24 w-24 mx-auto rounded-full shadow mb-4" alt="" />
                <img v-else src="/images/svg/profile.svg" class="h-24 w-24 mx-auto rounded-full shadow mb-4" alt="" />

                <a v-if="author_name" href="" class="text-lg font-semibold hover:text-red-600 transition-all duration-500 ease-in-out">{{ author_name }}</a>
                <p v-if="post.author.title" class="text-slate-400">{{ post.author.title }}</p>
              </div>

              <div v-if="recent_posts.length">
                <h5 class="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Zadnje objave</h5>
                <div v-for="recent_post in recent_posts" :key="recent_post.id" class="flex items-center mt-8">
                  <div class="ml-3">
                    <a :href="route('blog.details', recent_post.id)" class="font-semibold hover:text-red-600">{{ recent_post.title }}</a>
                    <p class="text-sm text-slate-400">{{ recent_post.created_at }}</p>
                  </div>
                </div>
              </div>
              <div v-if="types.length">
                <h5 class="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Objave po tipu</h5>
                <ul class="list-none text-center mt-8">
                  <li v-for="type in types" :key="type.id" class="inline-block m-2"><a :href="route('blog.by_type',type.id)" class="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-red-600 dark:hover:bg-red-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">{{ type.name }}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div><!--end grid-->
      </div><!--end container-->

      <subscribe />
    </section><!--end section-->
    <!-- End -->
  </div>
</template>
<script>
import Layout from '@/Shared/Landing/Layout'
import {Head} from '@inertiajs/inertia-vue3'
import Subscribe from '@/Shared/Landing/Subscribe'
import sanitizeHtml from 'sanitize-html'
export default {
    components: {
        Head,
        Subscribe,
    },
    layout: Layout,
    props: {
        post: Object,
        recent_posts: Array,
        types: Array,
    },
    data(){
        return {
            title: this.post.title,
            author_name: '',
        }
    },
    created() {
        if(this.post.author){
            this.author_name = this.post.author.first_name +' '+ this.post.author.last_name
        }
    },
    methods: {
        getReadingTime(text){
            const wpm = 225
            const words = text.trim().split(/\s+/).length
            const time = Math.ceil(words / wpm)
            return time+' minuta čitanja'
        },
        sanitizeHtml : sanitizeHtml,
    },
}
</script>
