<template>
  <div>
    <!-- Start -->
    <section class="relative bg-red-600/5">
      <div class="container-fluid relative">
        <div class="grid grid-cols-1">
          <div class="flex flex-col min-h-screen justify-center md:px-10 py-10 px-4">
            <div class="title-heading text-center my-auto">
              <img src="/images/error.svg" class="h-52 mx-auto" alt="" />
              <h1 class="mt-10 mb-4 md:text-4xl text-3xl font-bold">{{ title }}</h1>
              <p class="text-slate-400"> {{ description }} </p>

              <div class="mt-4">
                <a :href="route('home')" class="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md">Nazad na naslovnu</a>
              </div>
            </div>
          </div>
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <!-- End -->
  </div>
</template>

<script>
import Layout from '@/Shared/Landing/Layout'
export default {
    layout: Layout,
    props: {
        status: Number,
    },
    computed: {
        title() {
            return {
                503: '503: Servis nedostupan',
                500: '500: Server Error',
                404: '404: Stranica nije pronađena',
                403: '403: Zabranjen pristup',
            }[this.status]
        },
        description() {
            return {
                503: 'Oprostite, trenutno ne možemo pristupiti ovoj stranici.',
                500: 'Oprostite, došlo je do greške na serveru.',
                404: 'Oprostite, stranica koju tražite nije pronađena.',
                403: 'Oprostite, nemate dozvolu za pristup ovoj stranici.',
            }[this.status]
        },
    },
}
</script>
