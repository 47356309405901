<template>
  <div>
      <Head>
          <title>Privatnost i politika</title>
          <meta name="description" content="Uspoređivanjem različitih planova i osiguravajućih kuća, moći ćete pronaći najbolju ponudu koja odgovara vašim potrebama i financijskom situaciji." />
      </Head>
    <!-- Start Hero -->
    <section class="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
      <div class="container">
        <div class="grid grid-cols-1 text-center mt-10">
          <h1 class="text-3xl leading-normal font-semibold" v-html="sanitizeHtml(data.title)" />
          <p class="mt-2 text-base leading-7 text-slate-600">Zadnji put ažurirano: {{ data.updated_at }}</p>
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <div class="relative">
      <div class="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
        <svg class="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
        </svg>
      </div>
    </div>
    <!-- End Hero -->

    <!-- Start Privacy Policy -->
    <section class="relative md:py-20 py-16">
      <div class="container">
        <div class="md:flex justify-center">
          <div class="md:w-full">
            <div id="printArea" class="p-6 bg-white rounded-md leading-7 html" v-html="sanitizeHtml(page.content)" />
            <div class="mt-2 p-6 bg-white rounded-md leading-7">
              <span class="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md cursor-pointer" @click="print('printArea')">Print</span>
            </div>
          </div><!--end -->
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <!-- End Terms & Conditions -->
  </div>
</template>
<script>
import Layout from '@/Shared/Landing/Layout'
import { Head } from '@inertiajs/inertia-vue3'
import sanitizeHtml from 'sanitize-html'
export default {
    components: {
        Head,
    },
    layout: Layout,
    props: {
        data: Object,
    },
    data() {
        return {
            page: JSON.parse(this.data.html),
        }
    },
    methods: {
        print(area){
            const prtHtml = document.getElementById(area).innerHTML
            let stylesHtml = ''
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML
            }
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')
            WinPrint.document.write(`<!DOCTYPE html><html><head>${stylesHtml}</head><body>${prtHtml}</body></html>`)
            WinPrint.document.close()
            WinPrint.focus()
            WinPrint.print()
        },
        sanitizeHtml : sanitizeHtml,
    },
}
</script>
