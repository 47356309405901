<template>
  <nav id="topnav" class="defaultscroll is-sticky">
    <div id="dropdown" />
    <div class="container">
      <!-- Logo container-->
      <a :href="route('home')" class="logo pl-0 mt-2 mb-2">
        <logo class="help-desk-logo" />
      </a>

      <!-- End Logo container-->
      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <div id="isToggle" class="navbar-toggle cursor-pointer" @click="toggleMenu">
            <div class="lines">
              <span />
              <span />
              <span />
            </div>
          </div>
          <!-- End mobile menu toggle-->
        </div>
      </div>


      <div id="navigation">
        <!-- Navigation Menu-->
        <ul class="navigation-menu">
          <li :class="{'active' : active_menu === '/'}"><a :href="route('home')" class="sub-menu-item" @click="active_menu = 'about'">Naslovna</a></li>
          <li :class="{'active' : active_menu === 'o-nama'}"><a :href="route('about')" class="sub-menu-item" @click="active_menu = 'about'">O nama</a></li>
          <li :class="{'active' : active_menu === 'cesta-pitanja'}"><a :href="route('faq')" class="sub-menu-item" @click="active_menu = 'faq'">FAQs</a></li>
          <li :class="{'active' : active_menu === 'blog'}"><a :href="route('blog')" class="sub-menu-item" @click="active_menu = 'blog'">Blog</a></li>
          <li v-if="!$page.props.auth.user" class=""><a :href="route('login')">Prijava</a></li>
          <li v-if="$page.props.auth.user" class="dd__wrapper">
            <dropdown class="mt-1 select_user" placement="bottom-end">
              <template #default>
                <div class="flex items-center cursor-pointer group">
                  <div class=" mr-1 whitespace-nowrap">
                    <img v-if="$page.props.auth.user.photo" class="user_photo w-8 h-8" :alt="$page.props.auth.user.first_name" :src="$page.props.auth.user.photo" />
                    <img v-else src="/images/svg/profile.svg" class="w-8 h-8" alt="user profile" />
                  </div>
                  <icon class="w-5 h-5 drop-down-caret-icon" name="cheveron-down" />
                </div>
              </template>
              <template #dropdown>
                <div class="shadow-xl bg-white rounded text-sm">
                  <a class="block px-6 py-2 hover:bg-red-500 hover:text-white" :href="route('dashboard')">{{ __('Dashboard') }}</a>
                  <a class="block px-6 py-2 hover:bg-red-500 hover:text-white" :href="route('tickets')">{{ __('Tickets') }}</a>
                  <a class="block px-6 py-2 hover:bg-red-500 hover:text-white" :href="route('users.edit.profile')">{{ __('Edit Profile') }}</a>
                  <a class="block px-6 py-2 hover:bg-red-500 hover:text-white w-full text-left" :href="route('logout')" method="delete" as="button">{{ __('Logout') }}</a>
                </div>
              </template>
            </dropdown>
          </li>
          <li class="a_t_button"><Link :href="route('contact')" class="btn flex"><icon class="w-4 w-4 inline-block fill-gray-100" name="send_plan" /> Zovi na kafu</Link></li>
        </ul><!--end navigation menu-->
      </div><!--end navigation-->
    </div><!--end container-->
  </nav>
</template>
<script>
import Logo from '@/Shared/Logo'
import Dropdown from '@/Shared/Dropdown'
import Icon from '@/Shared/Icon'
import { Link } from '@inertiajs/inertia-vue3'
export default {
    components: {
        Logo,
        Icon,
        Dropdown,
        Link,
    },
    data(){
        return {
            active_menu: 'home',
        }
    },
    mounted() {
      this.active_menu = this.$page.url.substr(1) || 'home'
    },
    created() {
        window.addEventListener('scroll', (ev) => {
            ev.preventDefault()
            this.windowScroll()
        })
    },
    methods: {
        toggleMenu(){
            document.getElementById('isToggle').classList.toggle('open')
            var isOpen = document.getElementById('navigation')
            if (isOpen.style.display === 'block') {
                isOpen.style.display = 'none'
            } else {
                isOpen.style.display = 'block'
            }
        },
        windowScroll(){
            const navbar = document.getElementById('topnav')
            if (navbar != null) {
                if (
                    document.body.scrollTop >= 50 ||
                    document.documentElement.scrollTop >= 50
                ) {
                    navbar.classList.add('nav-sticky')
                } else {
                    navbar.classList.remove('nav-sticky')
                }
            }
        },
    },
}
</script>
