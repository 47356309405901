<template>
  <div class="container md:mt-24 mt-16">
    <div class="md:flex justify-center">
      <div class="lg:w-2/3 text-center">
        <h3 class="md:text-3xl text-2xl md:leading-normal leading-normal font-semibold mb-6">Pretplatite se na naš newsletter</h3>

        <p class="text-slate-400 max-w-xl mx-auto">Samo jednom sedmično ćemo vam slati najbolje od naših članaka.</p>

        <div class="mt-8">
          <div class="text-center subcribe-form">
            <form class="relative mx-auto max-w-xl" @submit.prevent="subscribe">
              <input id="subemail" v-model="form.email" type="email" name="email" class="pt-4 pr-[12rem] pb-4 pl-6 w-full h-[50px] outline-none text-black dark:text-white rounded-full bg-white/70 dark:bg-slate-900/70 border border-gray-100 dark:border-gray-700" placeholder="Unesite svoj email id.." />
              <loading-button :loading="form.processing" class="btn absolute top-[2px] right-[3px] h-[46px] bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-full" type="submit">Preplati se</loading-button>
            </form><!--end form-->
          </div>
        </div>
      </div>
    </div>
  </div><!--end container-->
</template>

<script>
import LoadingButton from '@/Shared/LoadingButton'
export default {
    components: {
        LoadingButton,
    },
    data() {
        return {
            form: this.$inertia.form({
                email: '',
            }),
        }
    },
    methods: {
        subscribe() {
            this.form.post(this.route('subscribe.news'))
            this.form.email = ''
        },
    },
}
</script>
