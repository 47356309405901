<template>
  <div>
    <Head :title="title" />
    <div class="bg-white rounded-md shadow overflow-hidden mr-2">
      <form @submit.prevent="update">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <text-input v-model="form.app_name" :error="form.errors.app_name" class="pr-6 pb-8 w-full lg:w-1/3" :label="__('App Name')" />
          <select-input v-model="form.default_language" :error="form.errors.default_language" class="pr-6 pb-8 w-full lg:w-1/3 hidden" :label="__('Default Language')">
            <option v-for="l in languages" :key="l.id" :value="l.code">{{ l.name }}</option>
          </select-input>
          <div class="pb-8 pr-6 w-full flex lg:w-1/3">
            <file-input v-model="form.logo" :error="form.errors.photo" class="pr-2 w-full lg:w-4/5" type="file" accept="image/png" label="Logo" />
            <div class="w-full lg:w-1/5 flex items-end justify-center">
              <img v-if="form.main_logo" class="block mb-2 " :src="form.main_logo" />
            </div>
          </div>
          <div class="assigned_user pr-6 pb-8 w-full lg:w-full flex flex-col hidden">
            <div class="font-bold text-sm mb-1">{{ __('Email Notifications') }} </div>
          </div>
          <div v-for="(notification, ni) in form.email_notifications" :key="ni" class="hidden flex items-center justify-start pr-6 pb-8 w-full lg:w-1/3">
            <label :for="notification.slug" class="flex toggle_swtich items-center cursor-pointer">
              <div class="relative">
                <input :id="notification.slug" v-model="notification.value" type="checkbox" class="sr-only" />
                <div class="w-10 h-4 bg-gray-400 rounded-full shadow-inner" />
                <div class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition" />
              </div>
              <div class="ml-3 text-sm">
                {{ notification.name }}
              </div>
            </label>
          </div>
          <div class="assigned_user pr-6 pb-8 w-full lg:w-full flex flex-col">
            <div class="font-bold text-sm mb-1">{{ __('Cron Job Instruction') }} </div>
            <p class="font-light">Ako želite da šaljete poštu bez odlaganja, potrebno je da za to postavite cron posao jednom u minuti.</p>
            <code class="font-sans block whitespace-pre mt-1 text-sm">/usr/bin/php artisan queue:work --queue=high,default --stop-when-empty</code>
            <p>Nakon što dodate gornji cron posao morate dodati <code>QUEUE_ENABLE=true</code> on the .env file </p>
          </div>
          <div class=" pr-6 pb-1 w-full lg:w-full flex flex-col hidden">
            <div class="font-bold text-sm mb-1">Sakriti polja osiguranja </div>
          </div>
          <div class="flex flex-wrap w-full pb-8 hidden">
            <label v-for="htf in hide_ticket_fields" class=" select-none flex items-center pr-8 capitalize" :for="htf">
              <input :id="htf" v-model="form.hide_ticket_fields" class="mr-1" :value="htf" type="checkbox" />
              <span class="text-sm">{{ htf.replace(/_/g, ' ') }}</span>
            </label>
          </div>
        </div>
        <div class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex items-center">
          <loading-button :loading="form.processing" class="btn-red ml-auto" type="submit">{{ __('Save') }}</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import FileInput from '@/Shared/FileInput'

export default {
  metaInfo: { title: 'Priorities' },
  components: {
      Head,
      FileInput,
      TextInput,
      SelectInput,
      LoadingButton,
  },
  layout: Layout,
  props: {
      title: String,
      settings: Object,
      languages: Array,
  },
    remember: 'form',
  data() {
    return {
        form: this.$inertia.form({
            app_name: this.settings.app_name.value,
            logo: null,
            main_logo: '/images/logo.png',
            default_language: this.settings.default_language.value,
            footer_text: this.settings.footer_text.value,
            email_notifications: this.settings.email_notifications.value.map(en=>{return {'name': en.name,'slug': en.slug,'value': !!en.value}}),
            hide_ticket_fields: this.settings.hide_ticket_fields && this.settings.hide_ticket_fields.value ? this.settings.hide_ticket_fields.value : [],
        }),
        hide_ticket_fields: [ 'department', 'assigned_to', 'ticket_type', 'category'],
    }
  },
    created() {
      console.log(this.settings.hide_ticket_fields)
      console.log(this.form)
    },
    methods: {
      update() {
          const vm = this
          this.form.post(this.route('global.update'), {
              onSuccess: () => {
                  const successMessage = vm.$page.props.flash.success
                  this.form.logo = null
                  if(successMessage){
                      window.location.reload()
                  }
              },
          })
      },
  },
}
</script>
