<template>
  <div>
    <!-- Start Hero -->
    <section class="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
      <div class="container">
        <div class="grid grid-cols-1 text-center mt-10">
          <h1 v-if="type.name" class="text-3xl leading-normal font-semibold">{{ type.name }}</h1>
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <div class="relative">
      <div class="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
        <svg class="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
        </svg>
      </div>
    </div>
    <!-- End Hero -->

    <!-- Start Section-->
    <section class="relative md:py-24 py-16">
      <div class="container">
        <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
          <div v-for="post in posts.data" :key="post.id" class="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
            <img v-if="post.image" :src="post.image" alt="" />

            <div class="content p-6">
              <a :href="route('blog.details', post.id)" class="title h5 text-lg font-medium hover:text-red-600 duration-500 ease-in-out">{{ post.title }}</a>
              <div class="text-slate-400 mt-3">
                {{ post.details.length < 70 ? post.details : post.details.substring(0,70) + "..." }}
              </div>
              <div class="mt-4">
                <a :href="route('blog.details', post.id)" class="btn btn-link font-normal hover:text-red-600 after:bg-red-600 duration-500 ease-in-out">Saznaj više <i class="uil uil-arrow-right" /></a>
              </div>
            </div>
          </div><!--blog end-->
        </div><!--end grid-->

        <pagination class="mt-6" :links="posts.links" />
      </div><!--end container-->

      <!-- Subscription -->
      <subscribe />
      <!-- Subscription -->
    </section><!--end section-->
    <!-- End -->
  </div>
</template>
<script>
import Layout from '@/Shared/Landing/Layout'
import Pagination from '@/Shared/Landing/Pagination'
import Subscribe from '@/Shared/Landing/Subscribe'
export default {
    components: {
        Pagination, Subscribe,
    },
    layout: Layout,
    props: {
        posts: Object,
        type: Object,
    },
}
</script>
