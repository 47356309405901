<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <Link class="text-red-400 hover:text-red-600" :href="route('categories')">Categories</Link>
      <span class="text-red-400 font-medium">/</span>
      {{ form.name }}
    </h1>
    <trashed-message v-if="category.deleted_at" class="mb-6" @restore="restore">
      This category has been deleted.
    </trashed-message>
    <div class="bg-white rounded-md shadow overflow-hidden max-w-3xl">
      <form @submit.prevent="update">
          <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
              <text-input v-model="form.name" :error="form.errors.name" class="pr-6 pb-8 w-full lg:w-1/2" label="Name" />
              <text-input v-model="form.color" :error="form.errors.color" class="pr-6 pb-8 w-full lg:w-1/2" label="Email" />
          </div>
        <div class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex items-center">
          <button v-if="!category.deleted_at" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy">Delete Category</button>
          <loading-button :loading="form.processing" class="btn-red ml-auto" type="submit">Update Category</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import { Link } from '@inertiajs/inertia-vue3'

export default {
  metaInfo() {
    return { title: this.form.name }
  },
  components: {
    LoadingButton,
    TextInput,
    TrashedMessage,
      Link
  },
  layout: Layout,
  props: {
    category: Object,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
          name: this.category.name,
          color: this.category.color,
      }),
    }
  },
  methods: {
    update() {
      this.form.put(this.route('categories.update', this.category.id))
    },
    destroy() {
      if (confirm('Are you sure you want to delete this category?')) {
        this.$inertia.delete(this.route('categories.destroy', this.category.id))
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this category?')) {
        this.$inertia.put(this.route('categories.restore', this.category.id))
      }
    },
  },
}
</script>
