
<template>
  <footer class="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
    <div class="container">
      <div class="grid grid-cols-12">
        <div class="col-span-12">
          <div class="py-[60px] px-0">
            <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
              <div class="lg:col-span-4 md:col-span-12">
                <a :href="route('home')" class="text-[22px] focus:outline-none">
                  <logo class="help-desk-logo" />
                </a>
                <p class="mt-6 text-gray-300">Važno je s kim radiš.</p>
              </div><!--end col-->

              <div class="lg:col-span-2 md:col-span-4">
                <p class="tracking-[1px] text-gray-100 font-semibold">Kompanija</p>
                <ul class="list-none footer-list mt-6">
                  <li class="mt-[10px]"><a :href="route('about')" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">O nama</a></li>
                  <li class="mt-[10px]"><a :href="route('blog')" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">Blog</a></li>
                  <li class="mt-[10px]"><a :href="route('login')" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">Prijava</a></li>
                </ul>
              </div><!--end col-->

              <div class="lg:col-span-3 md:col-span-4">
                <p class="tracking-[1px] text-gray-100 font-semibold">Korisni linkovi</p>
                <ul class="list-none footer-list mt-6">
                    <li><Link :href="route('faq')" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">FAQs</Link></li>
                    <li class="mt-[10px]"><Link :href="route('terms_service')" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">Uslovi korištenja</Link></li>
                  <li class="mt-[10px]"><Link :href="route('privacy')" class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">Politika privatnosti</Link></li>
                </ul>
              </div><!--end col-->

              <div class="lg:col-span-3 md:col-span-4">
                <p class="tracking-[1px] text-gray-100 font-semibold">Newsletter</p>
                <p class="mt-6">Budite informisani.</p>
                <form @submit.prevent="subscribe">
                  <div class="grid grid-cols-1">
                    <div class="foot-subscribe my-3">
                      <label class="form-label">Unesite svoj email <span class="text-red-600">*</span></label>
                      <div class="form-icon relative mt-2">
                        <icon class="w-4 w-4 absolute top-3 left-4 z-1 fill-gray-100" name="email" />
                        <input v-model="form.email" type="email" class="form-input bg-gray-800 border border-gray-800 text-gray-100 pl-12 focus:shadow-none" placeholder="Email" name="email" required="" />
                      </div>
                    </div>
                    <loading-button :loading="form.processing" class="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md justify-center" type="submit">Preplatite se</loading-button>
                  </div>
                </form>
              </div><!--end col-->
            </div><!--end grid-->
          </div><!--end col-->
        </div>
      </div><!--end grid-->
    </div><!--end container-->

    <div class="py-[30px] px-0 border-t border-slate-800">
      <div class="container text-center">
        <div class="grid md:grid-cols-2 items-center">
          <div class="md:text-left text-center">
              <p class="mb-0" >© {{new Date().getFullYear()}}. Ezra. Sva prava zadržana.</p>
          </div>
        </div><!--end grid-->
      </div><!--end container-->
    </div>
  </footer>
</template>

<script>
import Logo from '@/Shared/Logo'
import Icon from '@/Shared/Icon'
import LoadingButton from '@/Shared/LoadingButton'
import { Link } from '@inertiajs/inertia-vue3'
export default {
    components: {
        Logo, LoadingButton, Link, Icon,
    },
    props: {
        footer: Object,
    },
    data() {
        return {
            footer_content: JSON.parse(this.footer.html),
            form: this.$inertia.form({
                email: '',
            }),
        }
    },
    created() {
    },
    methods: {
        subscribe() {
            this.form.post(this.route('subscribe.news'))
            this.form.email = ''
        },
    },

}
</script>
