<template>
  <div>
  <Head>
      <title>Kontakt</title>
      <meta name="description" content="Javite se i osigurajte sebi osiguranje po mijeri" />
  </Head>
    <!-- Start Hero -->
    <section class="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
      <div class="container">
        <div class="grid grid-cols-1 text-center mt-10">
          <h1 class="text-3xl leading-normal font-semibold">Kontakt</h1>
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <div class="relative">
      <div class="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
        <svg class="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
        </svg>
      </div>
    </div>
    <!-- End Hero -->

    <!-- Start Section-->
    <section class="relative md:py-24 py-16">
      <div class="container">
        <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
          <div class="text-center px-6 mt-6">
            <div class="w-20 h-20 bg-red-600/5 text-red-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <icon class="h-8 w-8 fill-red-600" name="phone" />
            </div>

            <div class="content mt-7">
              <h5 class="title h5 text-xl font-medium">Telefon</h5>
              <div class="mt-5">
                  <a href="tel:+38761216006" class="btn btn-link text-red-600 hover:text-red-600 after:bg-red-600 duration-500 ease-in-out block">+387 61 216 006</a>
                <a href="tel:+38762658371" class="btn btn-link text-red-600 hover:text-red-600 after:bg-red-600 duration-500 ease-in-out block">+387 62 658 371</a>
              </div>
            </div>
          </div>

          <div class="text-center px-6 mt-6">
            <div class="w-20 h-20 bg-red-600/5 text-red-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <icon class="h-8 w-8 fill-red-600" name="email" />
            </div>

            <div class="content mt-7">
              <h5 class="title h5 text-xl font-medium">Email</h5>
              <div class="mt-5">
                <a href="mailto:emir@ezra.ba" class="btn btn-link text-red-600 hover:text-red-600 after:bg-red-600 duration-500 ease-in-out block">emir@ezra.ba</a>
                <a href="mailto:{{ page.email }}" class="btn btn-link text-red-600 hover:text-red-600 after:bg-red-600 duration-500 ease-in-out block">amir@ezra.ba</a>
              </div>
            </div>
          </div>

          <div class="text-center px-6 mt-6">
            <div class="w-20 h-20 bg-red-600/5 text-red-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <icon class="h-8 w-8 fill-red-600" name="location" />
            </div>

            <div class="content mt-7">
              <h5 class="title h5 text-xl font-medium">Lokacija</h5>
              <p class="text-slate-400 mt-3" v-html="sanitizeHtml(page.location)" />

              <div class="mt-5">
                <a
                  href="https://goo.gl/maps/8kRExPEQAkuRapj79" target="_blank"
                  data-type="iframe" class="video-play-icon read-more lightbox btn btn-link text-red-600 hover:text-red-600 after:bg-red-600 duration-500 ease-in-out"
                >Potraži na Google mapi</a>
              </div>
            </div>
          </div>
        </div><!--end grid-->
      </div><!--end container-->

      <div class="container md:mt-24 mt-16 relative">
        <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px] max-w-[700px] mx-auto">
          <div class="md:col-span-12 mt-8 md:mt-0">
            <div class="lg:ml-5">
              <div class="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                <h3 class="mb-6 text-2xl leading-normal font-medium">Uvijek dostupni.</h3>

                <form id="myForm" method="post" name="myForm" @submit.prevent="store">
                  <p id="error-msg" class="mb-0" />
                  <div id="simple-msg" />
                  <div class="grid lg:grid-cols-12 lg:gap-6">
                    <div class="lg:col-span-6 mb-5">
                      <div class="text-left">
                        <label for="name" class="font-semibold">Ime i prezime</label>
                        <div class="form-icon relative mt-2">
                          <input id="name" v-model="form.name" name="name" type="text" class="form-input" />
                        </div>
                      </div>
                    </div>

                    <div class="lg:col-span-6 mb-5">
                      <div class="text-left">
                        <label for="email" class="font-semibold">Email</label>
                        <div class="form-icon relative mt-2">
                          <input id="email" v-model="form.email" name="email" type="email" class="form-input" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-1">
                    <div class="mb-5">
                      <div class="text-left">
                        <label for="subject" class="font-semibold">Naslov</label>
                        <div class="form-icon relative mt-2">
                          <input id="subject" v-model="form.question" name="subject" class="form-input" />
                        </div>
                      </div>
                    </div>

                    <div class="mb-5">
                      <div class="text-left">
                        <label for="comments" class="font-semibold">Poruka</label>
                        <div class="form-icon relative mt-2">
                          <textarea id="comments" v-model="form.message" name="comments" class="form-input h-28" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button id="submit" type="submit" name="send" class="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md justify-center flex items-center">Pošalji</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div><!--end container-->
    </section><!--end section-->
    <!-- End Section-->

    <div class="container-fluid relative">
      <div class="grid grid-cols-1">
        <div class="w-full leading-[0] border-0">
          <iframe :src="page.location_map" style="border:0" class="w-full h-[500px]" allowfullscreen />
        </div>
      </div><!--end grid-->
    </div><!--end container-->
  </div>
</template>
<script>
import Layout from '@/Shared/Landing/Layout'
import Icon from '@/Shared/Icon'
import { Head } from '@inertiajs/inertia-vue3'
import sanitizeHtml from 'sanitize-html'
export default {
    components: {
        Icon,
        Head,
    },
    layout: Layout,
    props: {
        data: Object,
    },
    data() {
        return {
            page: JSON.parse(this.data.html),
            form: this.$inertia.form({
                name: '',
                email: '',
                question: '',
                message: '',
            }),
        }
    },
    methods:{
        sanitizeHtml : sanitizeHtml,
        store() {
            this.form.post(this.route('contact.send'),{
                onSuccess: () => {
                    this.form.reset()
                },
            })
        },
    },
}
</script>
