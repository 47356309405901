<template>
  <div>
    <!-- Start Hero -->
    <Head>
      <title>Važno je s kim radiš</title>
      <meta name="description" content="Uspoređivanjem različitih planova i osiguravajućih kuća, moći ćete pronaći najbolju ponudu koja odgovara vašim potrebama i financijskom situaciji." />
    </Head>
    <section class="relative table w-full md:py-44 py-36 bg-gray-50 dark:bg-slate-800">
      <div class="container">
        <div class="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
          <div class="md:col-span-7">
            <div class="ltr:md:mr-6 rtl:md:ml-6">
              <div class="bg-white dark:bg-slate-900 text-slate-400 py-2 px-4 inline-flex items-center font-medium rounded-full shadow dark:shadow-gray-800"><span class="bg-red-600 text-white text-[12px] font-bold px-2.5 py-0 rounded-full ltr:mr-2 rtl:ml-2">Ezra</span> Važno je s kim radiš</div>
              <h1 class="font-bold lg:leading-normal leading-normal text-4xl mb-5 mt-4">
                  Stvarno osigurani. Potpuno zbrinuti.<br /> <span class="text-red-600">Mi brinemo</span>, prije nego zatreba.
              </h1>
              <p class="text-slate-400 text-lg max-w-xl">Novi drug na pravom mjestu. Osigurani na pravi način. Misli na vrijeme.</p>

              <div class="mt-6">
                <Link :href="route('contact')" class="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md ltr:mr-2 rtl:ml-2 mt-2">Zovi na kafu</Link>
              </div>
            </div>
          </div><!--end col-->

          <div class="md:col-span-5">
            <div class="relative">
              <img src="/landing/images/insurance/hero-1.jpg" width="600" height="700" class="rounded-lg shadow-md dark:shadow-gray-800" alt="Ezra" />
            </div>
          </div><!--end col-->
        </div><!--end grid-->
      </div><!--end container -->
    </section>

    <!-- Start -->
    <!-- Start Section-->
    <section class="relative md:py-24 py-16">
      <div class="container">
        <div class="grid grid-cols-1 pb-8 items-end">
          <h2 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold"><span class="text-red-600">Misli na sebe. Uštedi vrijeme.</span> <br /> Dobij više opcija.</h2>
          <p class="text-slate-400 max-w-xl">Za tebe ćemo pronaći najbolja rješenja. Radimo samo za tebe, znamo šta tražimo i svaka su nam vrata otvorena.</p>
        </div><!--end grid-->
      </div><!--end container-->

      <div class="container-fluid relative mt-8">
        <div class="grid grid-cols-1 mt-8">
          <div class="tiny-six-item">
            <div class="tiny-slide" aria-hidden="true">
              <div class="mx-2">
                <div class="group relative block overflow-hidden rounded-md transition-all duration-500">
                  <span>
                    <img src="/landing/images/insurance/1.jpg" width="600" height="600" class="transition-all duration-500 group-hover:scale-105" alt="work-image" />
                    <div class="absolute inset-0 group-hover:bg-red-600 opacity-0 group-hover:opacity-70 transition-all duration-500" />
                  </span>
                </div>
                <div class="p-4 pb-0">
                    <Link :href="route('contact')" aria-hidden="true" class="btn btn-link hover:text-red-600 after:bg-red-600 text-xl duration-500 ease-in-out">Dodatno zdravstveno osiguranje</Link>
                </div>
              </div>
            </div>

            <div class="tiny-slide" aria-hidden="true">
              <div class="mx-2">
                <div class="group relative block overflow-hidden rounded-md transition-all duration-500">
                  <span>
                    <img src="/landing/images/insurance/2.jpg" width="600" height="600" class="transition-all duration-500 group-hover:scale-105" alt="work-image" />
                    <div class="absolute inset-0 group-hover:bg-red-600 opacity-0 group-hover:opacity-70 transition-all duration-500" />
                  </span>
                </div>
                <div class="p-4 pb-0">
                  <Link :href="route('contact')" aria-hidden="true" class="btn btn-link hover:text-red-600 after:bg-red-600 text-xl duration-500 ease-in-out">Životno osiguranje</Link>
                </div>
              </div>
            </div>

            <div class="tiny-slide" aria-hidden="true">
              <div class="mx-2">
                <div class="group relative block overflow-hidden rounded-md transition-all duration-500">
                  <span>
                    <img src="/landing/images/insurance/new-3.jpg" width="600" height="600" class="transition-all duration-500 group-hover:scale-105" alt="work-image" />
                    <div class="absolute inset-0 group-hover:bg-red-600 opacity-0 group-hover:opacity-70 transition-all duration-500" />
                  </span>
                </div>
                <div class="p-4 pb-0">
                  <Link :href="route('contact')" aria-hidden="true" class="btn btn-link hover:text-red-600 after:bg-red-600 text-xl duration-500 ease-in-out">Osiguranje stana ili kuće</Link>
                </div>
              </div>
            </div>

            <div class="tiny-slide" aria-hidden="true">
              <div class="mx-2">
                <div class="group relative block overflow-hidden rounded-md transition-all duration-500">
                  <span>
                    <img src="/landing/images/insurance/new-4.jpg" width="600" height="600" class="transition-all duration-500 group-hover:scale-105" alt="work-image" />
                    <div class="absolute inset-0 group-hover:bg-red-600 opacity-0 group-hover:opacity-70 transition-all duration-500" />
                  </span>
                </div>
                <div class="p-4 pb-0">
                  <Link :href="route('contact')" aria-hidden="true" class="btn btn-link hover:text-red-600 after:bg-red-600 text-xl duration-500 ease-in-out">Biznis paketi</Link>
                </div>
              </div>
            </div>

            <div class="tiny-slide" aria-hidden="true">
              <div class="mx-2">
                <div class="group relative block overflow-hidden rounded-md transition-all duration-500">
                  <span>
                    <img src="/landing/images/insurance/new-5.jpg" width="600" height="600" class="transition-all duration-500 group-hover:scale-105" alt="work-image" />
                    <div class="absolute inset-0 group-hover:bg-red-600 opacity-0 group-hover:opacity-70 transition-all duration-500" />
                  </span>
                </div>
                <div class="p-4 pb-0">
                  <Link :href="route('contact')" aria-hidden="true" class="btn btn-link hover:text-red-600 after:bg-red-600 text-xl duration-500 ease-in-out">Kasko osiguranje auta</Link>
                </div>
              </div>
            </div>

            <div class="tiny-slide" aria-hidden="true">
              <div class="mx-2">
                <div class="group relative block overflow-hidden rounded-md transition-all duration-500">
                  <span>
                    <img src="/landing/images/insurance/6.jpg" width="600" height="600" class="transition-all duration-500 group-hover:scale-105" alt="work-image" />
                    <div class="absolute inset-0 group-hover:bg-red-600 opacity-0 group-hover:opacity-70 transition-all duration-500" />
                  </span>
                </div>
                <div class="p-4 pb-0">
                  <Link :href="route('contact')" aria-hidden="true" class="btn btn-link hover:text-red-600 after:bg-red-600 text-xl duration-500 ease-in-out">Registracija auta</Link>
                </div>
              </div>
            </div>

            <div class="tiny-slide" aria-hidden="true">
              <div class="mx-2">
                <div class="group relative block overflow-hidden rounded-md transition-all duration-500">
                  <span>
                    <img src="/landing/images/insurance/new-7.jpg" width="600" height="600" class="transition-all duration-500 group-hover:scale-105" alt="work-image" />
                    <div class="absolute inset-0 group-hover:bg-red-600 opacity-0 group-hover:opacity-70 transition-all duration-500" />
                  </span>
                </div>
                <div class="p-4 pb-0">
                  <Link :href="route('contact')" aria-hidden="true" class="btn btn-link hover:text-red-600 after:bg-red-600 text-xl duration-500 ease-in-out">Profesionalna osiguranja</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><!--end container-->
    </section><!--end section-->
    <!-- End Section -->

    <!-- Start -->
    <section class="md:py-24 py-16 w-full table relative bg-gray-50 dark:bg-slate-800">
      <div class="container">
        <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
            <div v-for="(service, si) in servicesDataContent.services" :key="si" class="p-6 transition duration-500 rounded-2xl mt-6 text-center" v-bind:class="{'shadow-xl shadow-gray-100 dark:shadow-gray-800':si%2===0,'hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800':si%2!==0}">
                <div class="w-20 h-20 bg-white text-red-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <icon class="w-8 w-8 fill-red-600" :name="service.icon" />
            </div>

            <div class="content mt-7">
              <h3 class="title h5 text-lg font-medium hover:text-red-600 dark:text-gray-400">{{ service.name }}</h3>
              <p class="text-slate-400 mt-3" v-html="sanitizeHtml(service.details)" />
            </div>
          </div>
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <!-- End -->
    <!-- Start -->
    <section class="relative md:py-24 py-16">
      <div class="container">
        <div class="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
          <div class="relative">
            <h2 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold"><span class="text-red-600">Potpuno lično i </span> prilagođeno,<br /> a jasno i jednostavno.</h2>
            <p class="text-slate-400 max-w-xl">Bez stručnog žargona, glavobolje i mora nerazumljivih informacija. Opušteno i po mjeri.</p>
            <div class="mt-6">
              <Link :href="route('contact')" class="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md ltr:mr-2 rtl:ml-2 mt-2">Zovi na kafu</Link>
            </div>
          </div>

          <div class="ltr:lg:ml-8 rtl:lg:mr-8 md:mt-0 mt-8">
            <div id="accordion-collapseone" data-accordion="collapse">
              <div class="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden">
                <h3 id="accordion-collapse-heading-1" class="text-base font-semibold">
                  <button type="button" class="flex justify-between items-center p-5 w-full font-medium ltr:text-left rtl:text-right" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                    <span>Želiš li više vremena i manje posla?</span>
                    <svg data-accordion-icon class="w-4 h-4 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </button>
                </h3>
                <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
                  <div class="p-5">
                    <p class="text-slate-400 dark:text-gray-400">Neka pouzdan i stručan prijatelj u tvoje ime obavi sve što treba, i kako treba. Uštedi energiju i novac, jer mi ćemo se pobrinuti za sve. </p>
                  </div>
                </div>
              </div>

              <div class="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                <h3 id="accordion-collapse-heading-2" class="text-base font-semibold">
                  <button type="button" class="flex justify-between items-center p-5 w-full font-medium ltr:text-left rtl:text-right" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
                    <span>Želiš li više sigurnosti i manje brige? </span>
                    <svg data-accordion-icon class="w-4 h-4 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </button>
                </h3>
                <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
                  <div class="p-5">
                    <p class="text-slate-400 dark:text-gray-400">Šta ako se zaista desi osigurani rizik? Sigurno te nećemo ostaviti na cijedilu kada je najpotrebnije. Prijavi i naplati štetu bez stresa i uz advokatsku asistenciju.</p>
                  </div>
                </div>
              </div>

              <div class="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                <h3 id="accordion-collapse-heading-3" class="text-base font-semibold">
                  <button type="button" class="flex justify-between items-center p-5 w-full font-medium ltr:text-left rtl:text-right" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                    <span>Želiš li ljepše iskustvo?</span>
                    <svg data-accordion-icon class="w-4 h-4 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </button>
                </h3>
                <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                  <div class="p-5">
                    <p class="text-slate-400 dark:text-gray-400">Jer zaista nije svejedno s kim radiš. Dobar i lagodan osjećaj saradnje uz dobro pokriće i uslove, je najbolja opcija. Zovi na kafu i uvijeri se.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <!-- End -->

    <!-- Start -->
    <section class="py-44 w-full table relative lg:min-h-[800px]">
      <img class="absolute top-0 left-0 w-full h-full object-cover" src="/landing/assets/images/insurance/bg.jpg" alt="" />

      <div v-if="false" class="container relative">
        <div class="flex">
          <div class="lg:w-1/3 md:1/2 sm:w-2/3 px-6 py-8 rounded-md shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900">
            <div class="tiny-single-item">
              <div class="tiny-slide" aria-hidden="true">
                <div class="text-center">
                  <p class="text-lg text-slate-400 italic"> " Techwind made the processes so easy. Techwind instantly increased the amount of interest and ultimately saved us over $10,000. " </p>

                  <div class="text-center mt-5">
                    <ul class="text-xl font-medium text-amber-400 list-none mb-2">
                      <li class="inline"><i class="mdi mdi-star" /></li>
                      <li class="inline"><i class="mdi mdi-star" /></li>
                      <li class="inline"><i class="mdi mdi-star" /></li>
                      <li class="inline"><i class="mdi mdi-star" /></li>
                      <li class="inline"><i class="mdi mdi-star" /></li>
                    </ul>

                    <img src="/landing/assets/images/client/01.jpg" class="h-14 w-14 rounded-full shadow-md dark:shadow-gray-800 mx-auto" alt="" />
                    <h6 class="mt-2 font-semibold">Christa Smith</h6>
                    <span class="text-slate-400 text-sm">Manager</span>
                  </div>
                </div>
              </div>
              <div class="tiny-slide" aria-hidden="true">
                <div class="text-center">
                  <p class="text-lg text-slate-400 italic"> " I highly recommend Techwind as the new way to sell your home "by owner". My home sold in 24 hours for the asking price. Best $400 you could spend to sell your home. " </p>
                  <div class="text-center mt-5">
                    <ul class="text-xl font-medium text-amber-400 list-none mb-2">
                      <li class="inline"><i class="mdi mdi-star" /></li>
                      <li class="inline"><i class="mdi mdi-star" /></li>
                      <li class="inline"><i class="mdi mdi-star" /></li>
                      <li class="inline"><i class="mdi mdi-star" /></li>
                      <li class="inline"><i class="mdi mdi-star" /></li>
                    </ul>

                    <img src="/landing/assets/images/client/02.jpg" class="h-14 w-14 rounded-full shadow-md dark:shadow-gray-800 mx-auto" alt="" />
                    <h6 class="mt-2 font-semibold">Christa Smith</h6>
                    <span class="text-slate-400 text-sm">Manager</span>
                  </div>
                </div>
              </div>
              <div class="tiny-slide" aria-hidden="true">
                <div class="text-center">
                  <p class="text-lg text-slate-400 italic"> " My favorite part about selling my home myself was that we got to meet and get to know the people personally. This made it so much more enjoyable! " </p>

                  <div class="text-center mt-5">
                    <ul class="text-xl font-medium text-amber-400 list-none mb-2">
                      <li class="inline"><i class="mdi mdi-star" /></li>
                      <li class="inline"><i class="mdi mdi-star" /></li>
                      <li class="inline"><i class="mdi mdi-star" /></li>
                      <li class="inline"><i class="mdi mdi-star" /></li>
                      <li class="inline"><i class="mdi mdi-star" /></li>
                    </ul>

                    <img src="/landing/assets/images/client/03.jpg" class="h-14 w-14 rounded-full shadow-md dark:shadow-gray-800 mx-auto" alt="" />
                    <h6 class="mt-2 font-semibold">Christa Smith</h6>
                    <span class="text-slate-400 text-sm">Manager</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <!-- End -->

    <!-- Start -->
    <section class="relative md:py-24 py-16">
      <div class="container">
        <div class="grid grid-cols-1 pb-8 text-center">
          <h2 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Blog</h2>

          <p class="text-slate-400 max-w-xl mx-auto">Saznajte sve informacije i znanja koje vam trebaju da donesete najbolju odluku o osiguranju za sebe i svoju obitelj.</p>
        </div><!--end grid-->

        <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">
          <div v-for="post in posts.data" :key="post.id" class="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
            <img v-if="post.image" :src="post.image" alt="" class="object-fit" width="690" height="460" />
            <div class="content p-6">
              <Link :href="route('blog.details', post.id)" class="title h5 text-lg font-medium hover:text-red-600 duration-500 ease-in-out">{{ post.title }}</link>
              <div class="text-slate-400 mt-3" v-html="post.details.length < 70 ? post.details : post.details.substring(0,70) + '...'" />
              <div class="mt-4">
                <Link :href="route('blog.details', post.id)" class="btn btn-link font-normal hover:text-red-600 after:bg-red-600 duration-500 ease-in-out">Saznaj više<i class="uil uil-arrow-right" /></Link>
              </div>
            </div>
          </div>
        </div><!--end grid-->
      </div><!--end container-->

      <div class="container md:mt-24 mt-16">
        <div class="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
          <div class="relative">
            <h2 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold"><span class="text-red-600">Zovi, piši, pitaj… </span> <br /> Sve je lakše uz kafu.</h2>
            <p class="text-slate-400 max-w-xl">Nema minusa, samo možeš dobiti, zagarantovano. Upoznajmo se i razgovarajmo da utvrdimo da li smo “match”.</p>
          </div>

          <div class="p-6 ltr:lg:ml-16 rtl:lg:mr-16 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800">
            <h3 class="mb-3 text-2xl leading-normal font-semibold">Uvijek dostupni.</h3>
            <form id="myForm" method="post" name="myForm" @submit.prevent="store">
              <p id="error-msg" class="mb-0" />
              <div id="simple-msg" />
              <div class="grid lg:grid-cols-12 lg:gap-6">
                <div class="lg:col-span-6 mb-5">
                  <div class="text-left">
                    <label for="name" class="font-semibold">Ime i prezime</label>
                    <div class="form-icon relative mt-2">
                      <input id="name" v-model="form.name" name="name" type="text" class="form-input" />
                    </div>
                  </div>
                </div>

                <div class="lg:col-span-6 mb-5">
                  <div class="text-left">
                    <label for="email" class="font-semibold">Email</label>
                    <div class="form-icon relative mt-2">
                      <input id="email" v-model="form.email" name="email" type="email" class="form-input" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="grid grid-cols-1">
                <div class="mb-5">
                  <div class="text-left">
                    <label for="subject" class="font-semibold">Naslov</label>
                    <div class="form-icon relative mt-2">
                      <input id="subject" v-model="form.question" name="subject" class="form-input" />
                    </div>
                  </div>
                </div>

                <div class="mb-5">
                  <div class="text-left">
                    <label for="comments" class="font-semibold">Poruka</label>
                    <div class="form-icon relative mt-2">
                      <textarea id="comments" v-model="form.message" name="comments" class="form-input h-28" />
                    </div>
                  </div>
                </div>
              </div>
              <button id="submit" type="submit" name="send" class="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-md justify-center flex items-center">Pošalji</button>
            </form>
          </div>
        </div>
      </div><!--end container-->
    </section><!--end section-->
    <!-- End -->
  </div>
</template>


<script>
import { tns } from 'tiny-slider/src/tiny-slider'
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Landing/Layout'
import sanitizeHtml from 'sanitize-html'
import Icon from '@/Shared/Icon'


export default {
    components: {
        Head,
        Link,
        Icon,
    },
    layout: Layout,
    props: {
        title: String,
        page: Object,
        posts: Object,
        servicesData : Object,
    },

    data() {
        return {
            form: this.$inertia.form({
                name: '',
                email: '',
                question: '',
                message: '',
            }),
            servicesDataContent: JSON.parse(this.servicesData.html),
        }
    },
    mounted() {
        if(document.getElementsByClassName('tiny-six-item').length > 0) {
            var slider = tns({
                container: '.tiny-six-item',
                controls: true,
                mouseDrag: true,
                loop: true,
                rewind: true,
                autoplay: true,
                autoplayButtonOutput: false,
                autoplayTimeout: 3000,
                navPosition: 'bottom',
                controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
                nav: false,
                speed: 400,
                gutter: 0,
                responsive: {
                    1025: {
                        items: 6,
                    },

                    992: {
                        items: 4,
                    },

                    767: {
                        items: 3,
                    },

                    320: {
                        items: 1,
                    },
                },
            })
        }


        if(document.getElementsByClassName('tiny-single-item').length > 0) {
            var slider = tns({
                container: '.tiny-single-item',
                items: 1,
                controls: false,
                mouseDrag: true,
                loop: true,
                rewind: true,
                autoplay: true,
                autoplayButtonOutput: false,
                autoplayTimeout: 3000,
                navPosition: 'bottom',
                speed: 400,
                gutter: 16,
            })
        }
        try {
            const counter = document.querySelectorAll('.counter-value')
            const speed = 2500 // The lower the slower

            counter.forEach(counter_value => {
                const updateCount = () => {
                    const target = +counter_value.getAttribute('data-target')
                    const count = +counter_value.innerText

                    // Lower inc to slow and higher to slow
                    var inc = target / speed

                    if (inc < 1) {
                        inc = 1
                    }

                    // Check if target is reached
                    if (count < target) {
                        // Add inc to count and output in counter_value
                        counter_value.innerText = (count + inc).toFixed(0)
                        // Call function every ms
                        setTimeout(updateCount, 1)
                    } else {
                        counter_value.innerText = target
                    }
                }

                updateCount()
            })
        } catch (err) {
        }
        try {
            const Default = {
                defaultTabId: null,
                activeClasses: 'text-white bg-red-600',
                inactiveClasses: 'hover:text-red-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800',
                onShow: () => { },
            }

            class Tabs {
                constructor(items = [], options = {}) {
                    this._items = items
                    this._activeTab = options ? this.getTab(options.defaultTabId) : null
                    this._options = { ...Default, ...options }
                    this._init()
                }

                _init() {
                    if (this._items.length) {
                        // set the first tab as active if not set by explicitly
                        if (!this._activeTab) {
                            this._setActiveTab(this._items[0])
                        }

                        // force show the first default tab
                        this.show(this._activeTab.id, true)

                        // show tab content based on click
                        this._items.map(tab => {
                            tab.triggerEl.addEventListener('click', () => {
                                this.show(tab.id)
                            })
                        })
                    }
                }

                getActiveTab() {
                    return this._activeTab
                }

                _setActiveTab(tab) {
                    this._activeTab = tab
                }

                getTab(id) {
                    return this._items.filter(t => t.id === id)[0]
                }

                show(id, forceShow = false) {
                    const tab = this.getTab(id)

                    // don't do anything if already active
                    if (tab === this._activeTab && !forceShow) {
                        return
                    }

                    // hide other tabs
                    this._items.map(t => {
                        if (t !== tab) {
                            t.triggerEl.classList.remove(...this._options.activeClasses.split(' '))
                            t.triggerEl.classList.add(...this._options.inactiveClasses.split(' '))
                            t.targetEl.classList.add('hidden')
                            t.triggerEl.setAttribute('aria-selected', false)
                        }
                    })

                    // show active tab
                    tab.triggerEl.classList.add(...this._options.activeClasses.split(' '))
                    tab.triggerEl.classList.remove(...this._options.inactiveClasses.split(' '))
                    tab.triggerEl.setAttribute('aria-selected', true)
                    tab.targetEl.classList.remove('hidden')

                    this._setActiveTab(tab)

                    // callback function
                    this._options.onShow(this, tab)
                }

            }

            window.Tabs = Tabs

            document.addEventListener('DOMContentLoaded', () => {
                document.querySelectorAll('[data-tabs-toggle]').forEach(triggerEl => {

                    const tabElements = []
                    let defaultTabId = null
                    triggerEl.querySelectorAll('[role="tab"]').forEach(el => {
                        const isActive = el.getAttribute('aria-selected') === 'true'
                        const tab = {
                            id: el.getAttribute('data-tabs-target'),
                            triggerEl: el,
                            targetEl: document.querySelector(el.getAttribute('data-tabs-target')),
                        }
                        tabElements.push(tab)

                        if (isActive) {
                            defaultTabId = tab.id
                        }
                    })
                    new Tabs(tabElements, {
                        defaultTabId: defaultTabId,
                    })
                })
            })
        } catch (error) {

        }
        try {
            const Default = {
                alwaysOpen: false,
                activeClasses: 'bg-gray-50 dark:bg-slate-800 text-red-600',
                inactiveClasses: 'text-dark dark:text-white',
                onOpen: () => { },
                onClose: () => { },
                onToggle: () => { },
            }

            class Accordion {
                constructor(items = [], options = {}) {
                    this._items = items
                    this._options = { ...Default, ...options }
                    this._init()
                }

                _init() {
                    if (this._items.length) {
                        // show accordion item based on click
                        this._items.map(item => {

                            if (item.active) {
                                this.open(item.id)
                            }

                            item.triggerEl.addEventListener('click', () => {
                                this.toggle(item.id)
                            })
                        })
                    }
                }

                getItem(id) {
                    return this._items.filter(item => item.id === id)[0]
                }

                open(id) {
                    const item = this.getItem(id)

                    // don't hide other accordions if always open
                    if (!this._options.alwaysOpen) {
                        this._items.map(i => {
                            if (i !== item) {
                                i.triggerEl.classList.remove(...this._options.activeClasses.split(' '))
                                i.triggerEl.classList.add(...this._options.inactiveClasses.split(' '))
                                i.targetEl.classList.add('hidden')
                                i.triggerEl.setAttribute('aria-expanded', false)
                                i.active = false

                                // rotate icon if set
                                if (i.iconEl) {
                                    i.iconEl.classList.remove('rotate-180')
                                }
                            }
                        })
                    }

                    // show active item
                    item.triggerEl.classList.add(...this._options.activeClasses.split(' '))
                    item.triggerEl.classList.remove(...this._options.inactiveClasses.split(' '))
                    item.triggerEl.setAttribute('aria-expanded', true)
                    item.targetEl.classList.remove('hidden')
                    item.active = true

                    // rotate icon if set
                    if (item.iconEl) {
                        item.iconEl.classList.add('rotate-180')
                    }

                    // callback function
                    this._options.onOpen(this, item)
                }

                toggle(id) {
                    const item = this.getItem(id)

                    if (item.active) {
                        this.close(id)
                    } else {
                        this.open(id)
                    }

                    // callback function
                    this._options.onToggle(this, item)
                }

                close(id) {
                    const item = this.getItem(id)

                    item.triggerEl.classList.remove(...this._options.activeClasses.split(' '))
                    item.triggerEl.classList.add(...this._options.inactiveClasses.split(' '))
                    item.targetEl.classList.add('hidden')
                    item.triggerEl.setAttribute('aria-expanded', false)
                    item.active = false

                    // rotate icon if set
                    if (item.iconEl) {
                        item.iconEl.classList.remove('rotate-180')
                    }

                    // callback function
                    this._options.onClose(this, item)
                }

            }

            window.Accordion = Accordion

            document.addEventListener('DOMContentLoaded', () => {
                document.querySelectorAll('[data-accordion]').forEach(accordionEl => {

                    const alwaysOpen = accordionEl.getAttribute('data-accordion')
                    const activeClasses = accordionEl.getAttribute('data-active-classes')
                    const inactiveClasses = accordionEl.getAttribute('data-inactive-classes')

                    const items = []
                    accordionEl.querySelectorAll('[data-accordion-target]').forEach(el => {
                        const item = {
                            id: el.getAttribute('data-accordion-target'),
                            triggerEl: el,
                            targetEl: document.querySelector(el.getAttribute('data-accordion-target')),
                            iconEl: el.querySelector('[data-accordion-icon]'),
                            active: el.getAttribute('aria-expanded') === 'true' ? true : false,
                        }
                        items.push(item)
                    })

                    new Accordion(items, {
                        alwaysOpen: alwaysOpen === 'open' ? true : false,
                        activeClasses: activeClasses ? activeClasses : Default.activeClasses,
                        inactiveClasses: inactiveClasses ? inactiveClasses : Default.inactiveClasses,
                    })
                })
            })
        } catch (error) {

        }
    },
    methods:{
        sanitizeHtml : sanitizeHtml,
        store() {
            this.form.post(this.route('contact.send'),{
                onSuccess: () => {
                    this.form.reset()
                },
            })
        },
    },
}
</script>


